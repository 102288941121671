var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"appScreenRecord",staticStyle:{"height":"100%"},attrs:{"id":"annotation-root"}},[(_vm.media_uploading)?[_c('ProgressUploader',{attrs:{"title":_vm.media_uploading_title,"progress":_vm.media_uploading_progress}})]:_vm._e(),(_vm.loading || _vm.assessment_completed)?[_c('InterviewLoading')]:(!_vm.get_camera_recording_supported)?[_c('AppResourseNotFound',{attrs:{"title":_vm.error},on:{"refresh_process":_vm.init_process}})]:(!_vm.get_screen_recording_supported)?[_c('AppResourseNotFound',{attrs:{"title":_vm.error},on:{"refresh_process":_vm.init_process}})]:(!_vm.camera_media_enabled)?[_c('AccessDeniedComponent',{attrs:{"title":_vm.camera_enable_title(),"camera_and_microphone":true},on:{"share_camera":_vm.init_process}})]:(!_vm.media_enabled)?[_c('AccessDeniedComponent',{attrs:{"title":_vm.screen_enable_title(),"camera_and_microphone":false},on:{"share_screen":_vm.init_process}})]:(_vm.error)?[_c('AppResourseNotFound',{attrs:{"title":_vm.error},on:{"refresh_process":_vm.init_process}})]:[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"11"}},[_c('v-progress-linear',{staticClass:"progress-bar",attrs:{"height":"20","rounded":"","color":"primary","background-color":"transparent","value":((_vm.current_question_index + 1) /
              _vm.app_annotation_assessments.questions.length) *
            100}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"1"}},[_c('div',[_c('AppTimer',{key:_vm.current_question.id,attrs:{"time_seconds":_vm.current_question.question_time_allowed},on:{"time_over":_vm.process_next_question}})],1)])],1),_c('div',{staticClass:"d-flex flex-column align-center justify-center my-8"},[(
          _vm.current_question &&
          _vm.current_question.question_options &&
          _vm.current_question.question_options.title
        )?[_c('v-chip',{staticClass:"title-chip",attrs:{"color":"dark-darker"}},[_c('p',{staticClass:"mb-0 base-section-sub-title white--text",on:{"copy":_vm.on_copy}},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.current_question.question_options.title)+" ")])])])]:_vm._e(),_c('p',{staticClass:"base-body-text dark--text my-8 text-center",domProps:{"innerHTML":_vm._s(_vm.current_question.question_text)},on:{"copy":_vm.on_copy}}),(
          _vm.current_question &&
          _vm.current_question.question_options &&
          _vm.current_question.question_options.ref
        )?[(
            _vm.current_question.question_options.ref ===
            _vm.AppAssessments.AnnotationAssessmentRefs.PART1
          )?[_c('AppAnnotationPart1',{attrs:{"question":_vm.current_question},on:{"on_drag_end":_vm.submit_answer}})]:_vm._e(),(
            _vm.current_question.question_options.ref ===
            _vm.AppAssessments.AnnotationAssessmentRefs.PART2
          )?[_c('AppAnnotationPart2',{attrs:{"question":_vm.current_question},on:{"annotation-2":_vm.submit_answer}})]:_vm._e(),(
            _vm.current_question.question_options.ref ===
            _vm.AppAssessments.AnnotationAssessmentRefs.PART3
          )?[_c('AppAnnotationPart3',{attrs:{"question":_vm.current_question},on:{"annotation-3":_vm.submit_answer}})]:_vm._e(),(
            _vm.current_question.question_options.ref ===
            _vm.AppAssessments.AnnotationAssessmentRefs.PART4
          )?[_c('AppAnnotationPart4',{attrs:{"question":_vm.current_question},on:{"annotation-4":_vm.submit_answer}})]:_vm._e(),(
            _vm.current_question.question_options.ref ===
            _vm.AppAssessments.AnnotationAssessmentRefs.PART5
          )?[_c('AppAnnotationPart5',{attrs:{"question":_vm.current_question},on:{"annotation-5":_vm.submit_answer}})]:_vm._e()]:_vm._e(),_c('div',{staticClass:"mt-10 text-end",staticStyle:{"width":"100%"}},[_c('v-btn',{staticClass:"next-btn",class:{
            'base-submit-button': !_vm.disabled_next_question,
            'base-disable-button': _vm.disabled_next_question
          },attrs:{"width":"150px","loading":_vm.submit_ans_loading,"color":"primary","disabled":_vm.disabled_next_question},on:{"click":_vm.process_next_question}},[_vm._v(" "+_vm._s(_vm.$t("shared.next"))+" ")])],1)],2)],_c('div',{staticClass:"camera-position",class:_vm.get_site_direction === _vm.SiteDirections.RTL
        ? 'camera-position-arabic-site'
        : ''},[_c('video',{ref:"camera",staticClass:"rounded-div",attrs:{"preload":"auto","width":"100%","height":"100%","muted":"","id":"camera","autoplay":""},domProps:{"muted":true}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }