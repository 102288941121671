











import Vue from "vue";
import { format_seconds_to_minutes } from "@/utils/global";

export default Vue.extend({
  name: "AppTimer",
  data() {
    return {
      time: 0, // Time in seconds to countdown
      time_interval: 0 as any, // Interval to countdown,
      current_color_index: 0,
      colors: ["primary", "warning", "error"]
    };
  },
  props: {
    // Time in seconds to countdown (required)
    time_seconds: {
      type: Number,
      required: true
    },
    start_timer: {
      type: Boolean,
      default: true
    },
    intervals: {
      type: Number,
      default: 3
    }
  },
  mounted() {
    this.time = this.time_seconds; // Set the time to countdown
    if (this.start_timer) this.startTimer(); // Start the countdown
  },
  computed: {
    currentColor(): string {
      return this.colors[this.current_color_index];
    }
  },
  watch: {
    start_timer(value) {
      if (value) this.startTimer();
      // Start the countdown
      else clearInterval(this.time_interval); // Clear the interval
    }
  },
  methods: {
    format_seconds_to_minutes,
    // Start the countdown
    startTimer() {
      const intervals = this.intervals; // Number of intervals
      const intervalDuration = Math.floor(this.time_seconds / intervals); // Calculate interval duration
      this.time_interval = setInterval(() => {
        this.time--; // Decrease the time
        // Check if the current interval is over
        if (this.time % intervalDuration === 0) {
          this.changeColor(); // Change color
        }
        // If the time is over, clear the interval
        if (this.time <= 0) {
          clearInterval(this.time_interval); // Clear the interval
          this.$emit("time_over"); // Emit the time-over event
        }
      }, 1000);
    },
    changeColor() {
      this.current_color_index =
        (this.current_color_index + 1) % this.colors.length;
    }
  }
});
