


















































































import Vue from "vue";

export default Vue.extend({
  name: "AppAssessmentTermConditions",
  data() {
    return {
      dialog: true,
      items: [
        {
          text: this.$t("assessments.conditions.first"),
          icon: "mdi-circle"
        },
        {
          text: this.$t("assessments.conditions.second"),
          icon: "mdi-circle"
        },
        {
          text: this.$t("assessments.conditions.third"),
          icon: "mdi-circle"
        },
        {
          text: this.$t("assessments.conditions.fourth"),
          icon: "mdi-circle"
        },
        {
          text: this.$t("assessments.conditions.fifth"),
          icon: "mdi-circle"
        },
        {
          text: this.$t("assessments.conditions.sixth"),
          icon: "mdi-circle"
        }
      ]
    };
  },
  methods: {
    process(val: boolean) {
      this.dialog = false;
      this.$emit("process", val);
    }
  }
});
