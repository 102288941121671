






























/**
 * Example usage:
 * import ProgressUploader from "@/components/shared/ProgressUploader.vue";
 * In Script:
 * components: {
 *  ProgressUploader
 * }
 * In Template:
 * <progress-uploader :progress="progress" :title="title" />
 */
import Vue from "vue";
export default Vue.extend({
  name: "ProgressUploader",
  data() {
    return {
      animation: require("@/assets/illustrations/uploader.json")
    };
  },
  async created() {
    this.animation = JSON.stringify(this.animation);
  },
  // Component properties definition
  props: {
    progress: {
      type: Number,
      default: 0 // Default progress value
    },
    title: {
      type: String,
      default: "" // Default title
    },
    size: {
      type: Number,
      default: 120 // Default size of the progress bar
    },
    width: {
      type: Number,
      default: 8 // Default width of the progress bar
    },
    color: {
      type: String,
      default: "primary" // Default color of the progress bar
    },
    z_index: {
      type: Number,
      default: 999 // Default z-index for the overlay
    }
  }
});
